@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: Tahoma;
}


.btn {
    @apply  py-2 px-4 rounded;
}

.btn-primary {
    @apply bg-red-600 text-white hover:bg-red-700;
}

.btn-secondary {
    @apply bg-[#003049] text-white hover:bg-[#002f499e];
}

.btn-disabled {
    @apply bg-gray-300 text-gray-400 opacity-50 cursor-not-allowed;
}
.card {
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.custom-file-upload .p-button {
    background-color: #BA1500 !important; /* Change background color */
    color: white !important; /* Change text color */
    border: none !important; /* Remove border */
  }
  
  .custom-file-upload .p-button:hover {
    background-color: #8C1000 !important; /* Darker shade on hover */
  }
  .custom-file-upload .p-button:focus{
    outline: none !important;/* Remove focus outline */
    box-shadow: none; /* Remove box shadow */
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 5px;
    font-size: 14px;
  }
  
  .pagination button {
    padding: 5px 8px;
    min-width: 30px;
  }

  .custom-tabview .p-tabview-nav li.p-highlight a {
    border-bottom: 3px solid #003049 !important; 
    color: #003049 !important;
    font-weight: bold;
}

.custom-tabview .p-tabview-nav li a {
    transition: all 0.3s ease-in-out;
}


.custom-menubar .ant-menu-item {
  color: white !important;
  text-decoration: none !important;
}

.custom-menubar .ant-menu-item-selected {
  background-color: transparent !important;
  color: white !important;
}
.custom-menubar .ant-menu-item a {
  text-decoration: none !important;
}

.custom-menubar .ant-menu-item a:hover {
  text-decoration: none !important;
}